import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@utdanningsdirektoratet/icon";
import { Vedlegg as VedleggComponent } from "@utdanningsdirektoratet/vedlegg";
import { Popin, show, close } from "@utdanningsdirektoratet/popin";
import { FileInput } from "@utdanningsdirektoratet/fileinput";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { validationErrorsShape, vedleggShape } from "utils/PropTypes";
import classnames from "classnames";
import {
  newSuccessNotification as newSuccessNotificationAction,
  newErrorNotification as newErrorNotificationAction,
} from "ducks/notificationsDuck";
import Obligatorisk from "../Obligatorisk";
import * as serverActions from "./serverActions";

const Vedlegg = ({
  sporsmalId,
  actions,
  newErrorNotification,
  newSuccessNotification,
  updateSporsmalSvar,
  vedlegg,
  readonly,
  obligatorisk,
  forhandsvisning,
  validationErrors,
  eksternId,
  editManueltRegistert,
}) => {
  const [selected, setSelected] = useState({});
  const intl = useIntl();
  const popinId = `deleteVedleggPopin-${sporsmalId}`;
  const uploadFunc = (files) => {
    const callback = (result) => {
      if (result.errors !== "") {
        newErrorNotification(
          intl.formatMessage({ id: "validation.opplastingFerdigMedFeil" }),
          intl.formatMessage({ id: "validation.opplastingFerdigMedFeilMessage" }, { 0: result.errors })
        );
      } else {
        newSuccessNotification(intl.formatMessage({ id: "validation.opplastingFerdig" }), null, true);
      }
      updateSporsmalSvar(sporsmalId, { vedlegg: [...vedlegg, ...result.files] });
    };
    const handleError = () => {
      newErrorNotification(
        intl.formatMessage({ id: "validation.opplastingFerdigMedFeil" }),
        intl.formatMessage({ id: "validation.opplastingFerdigMedFeilMessage" }, { 0: files.map((file) => file.name).join(", ") })
      );
    };
    if (editManueltRegistert) {
      serverActions.lastOppVedleggManueltRegistrert(sporsmalId, files, eksternId, callback, handleError);
    } else {
      actions.lastOppVedlegg(sporsmalId, files, callback, handleError);
    }
  };

  const showPopin = (_eksternId) => {
    const _selected = vedlegg.find((v) => v.eksternId === _eksternId);
    if (_selected) {
      setSelected(_selected);
      show(popinId);
    } else {
      console.error(`Fant ikke vedlegg med eksternId ${_eksternId} i showPopin`); // eslint-disable-line no-console
    }
  };

  const deleteFuncWrapped = () => {
    if (selected?.eksternId) {
      close(popinId);
      actions.slettVedlegg(
        sporsmalId,
        selected.eksternId,
        (result) => {
          if (result.error) {
            newErrorNotification(intl.formatMessage({ id: "validation.slettingAvVedleggMislyktes" }));
          } else {
            newSuccessNotification(intl.formatMessage({ id: "validation.vedleggSlettet" }), null, true);
            updateSporsmalSvar(sporsmalId, { vedlegg: vedlegg.filter((v) => v.eksternId !== result.eksternId) });
          }
        },
        () => {
          newErrorNotification(intl.formatMessage({ id: "validation.slettingAvVedleggMislyktes" }));
        }
      );
    } else {
      console.error("Prøver å slette vedlegg uten eksternId lagret i staten i deleteFuncWrapped"); // eslint-disable-line no-console
    }
  };

  const vedleggs = vedlegg.map((v) => {
    return (
      <VedleggComponent
        key={`vedlegg-${v.eksternId}`}
        title={v.filNavn}
        href={v.url}
        onDelete={() => showPopin(v.eksternId)}
        disabled={readonly}
        className="Vedlegg--uploaded u--marginBottom1"
      />
    );
  });

  const label = (
    <span>
      <Icon icon="document" type="small" placement="before" />
      <FormattedMessage id="formLabels.leggTilVedlegg" />
    </span>
  );

  const selectedVedleggFilename = selected.filNavn;

  if (!forhandsvisning && readonly) {
    if (vedleggs.length === 0) {
      return (
        <div className="Sporsmal-body Sporsmal-body--italic">
          <FormattedMessage id="validation.ingenVedlegg" />
        </div>
      );
    }
  }

  const error = validationErrors.length > 0;
  const buttonClass = classnames({
    "Sporsmal-button--error": error,
    "Sporsmal-button-vedlegg": true,
  });

  const validationErrorMessagesMalform = validationErrors.map((err) => intl.formatMessage(err.text)).join("\n");

  let button = null;
  if (forhandsvisning || !readonly) {
    button = (
      <Tooltip message={validationErrorMessagesMalform} position="right" disabled={!error} alwaysShow>
        <Obligatorisk isObligatorisk={obligatorisk}>
          <FileInput className={buttonClass} onChange={uploadFunc} disabled={readonly} label={label} multiple />
        </Obligatorisk>
      </Tooltip>
    );
  }

  return (
    <>
      <div className="Sporsmal-body">
        {vedleggs.length > 0 ? (
          <div className="u--label">
            <FormattedMessage id="formLabels.vedlegg" />
          </div>
        ) : null}
        <div className="Vedlegg-container">{vedleggs}</div>
        {button}
      </div>
      <Popin
        id={popinId}
        title={<FormattedMessage id="formLabels.slettVedlegg" />}
        closeableByExternalEvents
        saveButton={<FormattedMessage id="formLabels.slett" />}
        onSave={deleteFuncWrapped}
        cancelButton={<FormattedMessage id="formLabels.avbryt" />}
      >
        <FormattedMessage id="formLabels.slettVedleggTekst" values={{ 0: selectedVedleggFilename }} />
      </Popin>
    </>
  );
};

Vedlegg.propTypes = {
  sporsmalId: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  updateSporsmalSvar: PropTypes.func.isRequired,
  newSuccessNotification: PropTypes.func.isRequired,
  newErrorNotification: PropTypes.func.isRequired,
  vedlegg: PropTypes.arrayOf(vedleggShape),
  readonly: PropTypes.bool.isRequired,
  obligatorisk: PropTypes.bool,
  forhandsvisning: PropTypes.bool.isRequired,
  validationErrors: validationErrorsShape.isRequired,
  editManueltRegistert: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    newSuccessNotification: bindActionCreators(newSuccessNotificationAction, dispatch),
    newErrorNotification: bindActionCreators(newErrorNotificationAction, dispatch),
    actions: bindActionCreators(serverActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    eksternId: state.skjema.eksternId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vedlegg);
