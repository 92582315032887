import { show } from "@utdanningsdirektoratet/popin";
import { postApi } from "api/fetchWrapper";
import mapVm from "vm/mapVm";
import { sendInnPopinId, lagreUtkastPopinId, videresendUtkastPopinId } from "constants/popinIds";
import { validateAll, validateOppgittUnderskjema, firstInvalidId } from "ducks/validationDuck";
import { saveNow } from "ducks/autoSaveDuck";
import { newErrorNotification } from "ducks/notificationsDuck";
import {
  navigateToUnderskjema as navigateToUnderskjemaAction,
  navigateToSeksjon as navigateToSeksjonAction,
  navigateToSporsmal as navigateToSporsmalAction,
  navigateToFootnote as navigateToFootnoteAction,
} from "ducks/navigationDuck";

import {
  setIngenMerknader,
  updateSporsmalSvarInit as updateSporsmalSvarInitAction,
  updateSporsmalSvar as updateSporsmalSvarAction,
  updateSeksjonSvar as updateSeksjonSvarAction,
  updateUnderskjemaSvar as updateUnderskjemaSvarAction,
  getSporsmalSvar,
  getSeksjonSvar,
  getUnderskjemaSvar,
} from "ducks/skjemaSvarDuck";

import { ExternalSkjemaClient } from "../../ApiClients";

const client = new ExternalSkjemaClient();

const isValid = (dispatch, getState) => {
  const invalid = firstInvalidId(getState().validation);

  if (invalid.sporsmal) {
    navigateToSporsmalAction(invalid.sporsmal)(dispatch, getState);
    return false;
  }

  if (invalid.seksjoner) {
    navigateToSeksjonAction(invalid.seksjoner)(dispatch, getState);
    return false;
  }

  if (invalid.underskjema) {
    navigateToUnderskjemaAction(invalid.underskjema, true)(dispatch, getState);
    return false;
  }

  return true;
};

export const ingenMerknader = () => {
  return (dispatch) => {
    dispatch(setIngenMerknader(true));
    show(sendInnPopinId);
  };
};

export const ingenMerknaderManuellRegistrert = (eksternId, manuellRegistreringsKode, value) => {
  return (dispatch) => {
    dispatch(setIngenMerknader(value));
    postApi(client.setIngenMerknader(eksternId, manuellRegistreringsKode, value))
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const showLagreOgSendInn = () => {
  return (dispatch, getState) => {
    dispatch(setIngenMerknader(false));
    validateAll()(dispatch, getState);
    if (isValid(dispatch, getState)) {
      show(sendInnPopinId);
    }
  };
};

export const lagreOgGaVidere = (currentUnderskjemaId, nextUnderskjemaId) => {
  return (dispatch, getState) => {
    dispatch(setIngenMerknader(false));
    validateOppgittUnderskjema(currentUnderskjemaId)(dispatch, getState);
    if (isValid(dispatch, getState)) {
      navigateToUnderskjemaAction(nextUnderskjemaId)(dispatch, getState);
    }
  };
};

export const navigateToUnderskjema = (underskjemaId, scrollTo) => {
  return (dispatch, getState) => {
    navigateToUnderskjemaAction(underskjemaId, scrollTo)(dispatch, getState);
  };
};

export const navigateToFootnote = (fotnoteId, underskjemaId) => {
  return (dispatch, getState) => {
    navigateToFootnoteAction(fotnoteId, underskjemaId)(dispatch, getState);
  };
};

export const lagreOgGaTilbake = (eksternId, skjemaSvar, skjema, manuellRegistreringsKode, returnTo) => {
  return (dispatch, getState) => {
    const vm = mapVm(eksternId, skjemaSvar, getState().initialSkjemaSvar, skjema, getState().locale);
    postApi(client.lagreManuellRegistrering(skjema.skjemaId, eksternId, manuellRegistreringsKode, vm))
      .then(() => {
        window.location = returnTo;
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const showLagreUtkast = () => {
  return (dispatch) => {
    saveNow()(dispatch)
      .then(() => {
        show(lagreUtkastPopinId);
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const showVideresendUtkast = () => {
  return (dispatch) => {
    saveNow()(dispatch)
      .then(() => {
        show(videresendUtkastPopinId);
      })
      .catch((error) => {
        dispatch(newErrorNotification(error.title, error.message));
      });
  };
};

export const updateSporsmalSvar = (sporsmalId, sporsmalSvar, init = false) => {
  return (dispatch, getState) => {
    if (init) {
      dispatch(
        updateSporsmalSvarInitAction(
          sporsmalId,
          sporsmalSvar,
          getState().sporsmal[sporsmalId],
          getSporsmalSvar(sporsmalId, getState().skjemaSvar)
        )
      );
    } else {
      dispatch(
        updateSporsmalSvarAction(
          sporsmalId,
          sporsmalSvar,
          getState().sporsmal[sporsmalId],
          getSporsmalSvar(sporsmalId, getState().skjemaSvar)
        )
      );
    }
  };
};

export const updateSeksjonSvar = (seksjonId, seksjonSvar) => {
  return (dispatch, getState) => {
    dispatch(
      updateSeksjonSvarAction(seksjonId, seksjonSvar, getState().seksjon[seksjonId], getSeksjonSvar(seksjonId, getState().skjemaSvar))
    );
  };
};

export const updateUnderskjemaSvar = (underskjemaId, underskjemaSvar) => {
  return (dispatch, getState) => {
    dispatch(
      updateUnderskjemaSvarAction(
        underskjemaId,
        underskjemaSvar,
        getState().underskjema[underskjemaId],
        getUnderskjemaSvar(underskjemaId, getState().skjemaSvar)
      )
    );
  };
};
