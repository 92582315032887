import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { Element } from "react-scroll";
import DocumentMetadata from "components/DocumentMetadata";
import AutoSaveLoader from "components/AutoSaveLoader";
import ReactRouterPropTypes from "react-router-prop-types";
import { BekreftetStatus, PubliseringsStatus } from "constants/serverEnums";
import { institusjonLongName } from "logic/initialState";
import { skjemaShape, underskjemaShape } from "utils/PropTypes";
import { getUnderskjema, getNextUnderskjema } from "ducks/skjemaDuck";
import { sendInnPopinId, lagreUtkastPopinId, videresendUtkastPopinId } from "constants/popinIds";
import Footer from "components/Footer";
import { getLocaleCode } from "utils/localeUtils";
import TutorialOverlay from "./components/TutorialOverlay";
import InnsendtHeader from "./components/skjemaSvar/InnsendtHeader";
import IngenMerknader from "./components/skjemaSvar/IngenMerknader";
import IngenMerknaderManueltRegistrert from "./components/skjemaSvar/IngenMerknaderManueltRegistrert";
import Innholdsfortegnelse from "./components/Innholdsfortegnelse";
import LagreOgGaTilbake from "./components/skjemaSvar/LagreOgGaTilbake";
import LagreOgSendInn from "./components/skjemaSvar/LagreOgSendInn";
import SeksjonSvar from "./components/svar/SeksjonSvar";
import UnderskjemaSvar from "./components/svar/UnderskjemaSvar";
import Signatur from "./components/skjemaSvar/Signatur";
import Fotnoter from "./components/skjemaSvar/Fotnoter";
import Vedlegg from "./components/skjemaSvar/Vedlegg";
import SendInnPopin from "./components/sendInn/SendInnPopin";
import LagreUtkastPopin from "./components/lagreUtkast/LagreUtkastPopin";
import VideresendUtkastPopin from "./components/videresendUtkast/VideresendUtkastPopin";
import MessageBanner from "./components/skjemaSvar/MessageBanner";
import Header from "./components/skjemaSvar/Header";
import Sidebar from "./components/sidebar/Sidebar";
import * as svarActions from "./svarActions";

class SkjemaSvar extends PureComponent {
  componentDidMount() {
    if (!this.props.currentUnderskjema) {
      this.props.actions.navigateToUnderskjema(this.props.skjema.underskjema[0].id);
    }

    document.addEventListener("click", this.onClick);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.currentUnderskjema) {
      nextProps.actions.navigateToUnderskjema(nextProps.skjema.underskjema[0].id);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClick);
  }

  onClick = (e) => {
    if (e.target.classList.contains("fotnote")) {
      e.preventDefault();
      this.props.actions.navigateToFootnote(e.target.dataset.fotnoteId, this.props.currentUnderskjema.id);
    }
  };

  render() {
    const { skjema, skjemaSvar, currentUnderskjema, nextUnderskjema, actions, location, hasFeedback, locale } = this.props;

    if (!currentUnderskjema) return null;

    const {
      frist,
      publisert,
      svarBekreftelse,
      innsender,
      endret,
      skjemaId,
      eksternId,
      innsendt,
      readOnly,
      manuellRegistreringsKode,
      publiseringsStatus,
      forhandsvisning,
      utgatt,
      manueltRegistrert,
      showTutorialOverlay,
      sendInnKunSisteUnderskjema,
      enSakPerSvarInnstilling,
    } = skjema;

    const { saksNr, avsender, tittel, fotnoter } = currentUnderskjema;
    const editManueltRegistert = manueltRegistrert && !!manuellRegistreringsKode;
    const arkivnummer = innsendt && !editManueltRegistert && enSakPerSvarInnstilling ? skjema.arkivnummer : saksNr;

    const pageClass = classnames({
      Page: true,
      "Page--readonly": readOnly || innsendt,
      "Page--innsendt": innsendt && !editManueltRegistert,
    });

    if (!forhandsvisning && publiseringsStatus === PubliseringsStatus.IkkePublisert) {
      return (
        <>
          <DocumentMetadata title={institusjonLongName} />
          <div className="Grid">
            <div className={pageClass}>
              <Element name="headerScroll" />
              <Header />
              <MessageBanner skjema={skjema} />
            </div>
          </div>
          <Footer skjemaId={skjemaId} />
        </>
      );
    }

    const hasSidebar = skjema.underskjema.filter((n) => !innsendt || n.underskjemaSvar.hasFeedback || n.rekkefolge === 0).length > 1;
    const gridClass = classnames({
      Grid: true,
      "Grid--offset": hasSidebar || (editManueltRegistert && skjema.underskjema.length > 1),
      "Grid--innsendt": innsendt && !editManueltRegistert,
    });

    const underskjemaHasSporsmals =
      currentUnderskjema !== skjema.underskjema[0] ||
      currentUnderskjema.kanHaGenerellHoldning ||
      currentUnderskjema.kanHaGenerellKommentar ||
      currentUnderskjema.seksjons.some((s) => s.kanHaMarkering) ||
      currentUnderskjema.seksjons.some((s) => s.sporsmals.length > 0);

    return (
      <>
        <DocumentMetadata title={tittel[getLocaleCode(locale)] ?? ""} />
        <AutoSaveLoader />
        <div className={gridClass}>
          <Sidebar
            currentUnderskjema={currentUnderskjema}
            underskjema={skjema.underskjema}
            location={location}
            endret={endret}
            innsendt={innsendt && !editManueltRegistert}
            showTimestamp={!innsendt && !readOnly}
          />
          <div className={pageClass}>
            <header>
              <Element name="headerScroll" />
              <InnsendtHeader
                show={innsendt && !editManueltRegistert}
                innsender={innsender}
                eksternId={eksternId}
                navnSkjult={skjema.navnSkjult}
              />

              <Header avsender={avsender} frist={frist} publisert={publisert} referanse={arkivnummer} />
            </header>
            <main id="main">
              <div className="Page-section">
                <h1 className="Page-title u-pageTitle">{tittel[getLocaleCode(locale)] ?? ""}</h1>
              </div>

              <MessageBanner skjema={skjema} editManueltRegistert={editManueltRegistert} />

              {editManueltRegistert ? (
                <IngenMerknaderManueltRegistrert
                  onClick={(ingenMerknader) => () =>
                    actions.ingenMerknaderManuellRegistrert(skjema.eksternId, manuellRegistreringsKode, ingenMerknader)
                  }
                  kanHaIngenMerknader={skjema.ingenMerknader.enabled}
                  harIngenMerknader={skjemaSvar.ingenMerknader}
                />
              ) : (
                <IngenMerknader
                  readonly={innsendt || readOnly}
                  onClick={actions.ingenMerknader}
                  hasFeedback={hasFeedback}
                  kanHaIngenMerknader={skjema.ingenMerknader.enabled}
                  harIngenMerknader={skjemaSvar.ingenMerknader}
                />
              )}

              {currentUnderskjema.visInnholdsfortegnelse && (
                <Innholdsfortegnelse seksjoner={currentUnderskjema.seksjons} skjulNummerering={currentUnderskjema.skjulNummerering} />
              )}

              <SeksjonSvar
                readonly={innsendt || readOnly}
                editManueltRegistert={editManueltRegistert}
                updateSporsmalSvar={actions.updateSporsmalSvar}
                updateSeksjonSvar={actions.updateSeksjonSvar}
                seksjons={currentUnderskjema.seksjons}
                skjulNummerering={currentUnderskjema.skjulNummerering}
                forhandsvisning={(utgatt && !innsendt) || forhandsvisning || editManueltRegistert}
              />

              <UnderskjemaSvar
                readonly={innsendt || readOnly}
                editManueltRegistert={editManueltRegistert}
                underskjema={currentUnderskjema}
                updateUnderskjemaSvar={actions.updateUnderskjemaSvar}
                forhandsvisning={(utgatt && !innsendt) || forhandsvisning || editManueltRegistert}
              />

              <Signatur signatur={currentUnderskjema.signatur} />

              <Fotnoter fotnoter={fotnoter} underskjemaId={currentUnderskjema.id} />

              <Vedlegg vedlegg={skjema.vedlegg} underskjemaVedlegg={currentUnderskjema.vedlegg} />

              {editManueltRegistert ? (
                <LagreOgGaTilbake
                  actions={actions}
                  skjema={skjema}
                  skjemaSvar={skjemaSvar}
                  manuellRegistreringsKode={manuellRegistreringsKode}
                  returnTo={new URLSearchParams(location.search).get("returnTo")}
                />
              ) : (
                <LagreOgSendInn
                  actions={actions}
                  innsendt={innsendt}
                  forhandsvisning={forhandsvisning}
                  hasFeedback={hasFeedback}
                  readOnly={readOnly}
                  nextUnderskjema={nextUnderskjema}
                  currentUnderskjema={currentUnderskjema}
                  sendInnKunSisteUnderskjema={sendInnKunSisteUnderskjema}
                  underskjemaHasSporsmals={underskjemaHasSporsmals}
                />
              )}

              <SendInnPopin id={sendInnPopinId} eksternId={eksternId} />
              <LagreUtkastPopin id={lagreUtkastPopinId} eksternId={eksternId} />
              <VideresendUtkastPopin id={videresendUtkastPopinId} eksternId={eksternId} />
            </main>
          </div>
        </div>
        <Footer skjemaId={skjemaId} />
        <TutorialOverlay
          eksternId={eksternId}
          isOpen={innsendt && svarBekreftelse === BekreftetStatus.IkkeBekreftet && showTutorialOverlay}
          epost={innsender.epost}
          innsendtDato={innsender.innsendtDato}
        />
      </>
    );
  }
}

SkjemaSvar.propTypes = {
  skjema: skjemaShape.isRequired,
  currentUnderskjema: underskjemaShape.isRequired,
  nextUnderskjema: underskjemaShape.isRequired,
  actions: PropTypes.object.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  hasFeedback: PropTypes.bool,
  skjemaSvar: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(svarActions, dispatch),
  };
};

const mapStateToProps = (state, ownProps) => {
  const underskjemaListWithFeedback = state.skjema.underskjema.filter((n) => n.underskjemaSvar.hasFeedback);
  const selectedUnderskjema = underskjemaListWithFeedback.length > 0 ? underskjemaListWithFeedback[0] : state.skjema.underskjema[0];
  const underskjemaId = ownProps.underskjemaParam ? parseInt(ownProps.underskjemaParam, 10) : selectedUnderskjema.id;

  return {
    currentUnderskjema: getUnderskjema(underskjemaId, state.skjema),
    nextUnderskjema: getNextUnderskjema(underskjemaId, state.skjema),
    skjema: state.skjema,
    hasFeedback: state.skjemaSvar.hasFeedback,
    skjemaSvar: state.skjemaSvar,
    locale: state.locale,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkjemaSvar);
