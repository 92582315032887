import { Route, Switch } from "react-router";
import Progress from "react-progress-2";
import { SlowAction } from "@utdanningsdirektoratet/slowaction";
import { PopinManager } from "@utdanningsdirektoratet/popin";
import * as Sentry from "@sentry/react";

import withLayout from "components/Layout";
import Svar from "./pages/svar";
import Default from "./pages/default";

import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/nb";
import "@formatjs/intl-pluralrules/locale-data/nn";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/nb";
import "@formatjs/intl-relativetimeformat/locale-data/nn";

const SentryRoute = Sentry.withSentryRouting(Route);

const App = () => {
  return (
    <>
      <Progress.Component />
      <Switch>
        <SentryRoute
          exact
          path={[
            "/Skjema/:skjemaId",
            "/Skjema/v2/:skjemaId",
            "/SkjemaSvar/:eksternId",
            "/SkjemaSvar/v2/:eksternId",
            "/LesUtkast/:leseTilgangsKode",
            "/LesUtkast/v2/:leseTilgangsKode",
            "/Utkast/:eksternId",
            "/Utkast/v2/:eksternId",
            "/Forhaandsvisning/:skjemaId/:forhaandsVisningsKode",
            "/Forhaandsvisning/v2/:skjemaId/:forhaandsVisningsKode",
            "/ManuellRegistrering/:eksternId/:manuellRegistreringsKode",
            "/ManuellRegistrering/v2/:eksternId/:manuellRegistreringsKode",
            "/LukketInnsending/:skjemaId/:innsendingsKode",
            "/LukketInnsending/v2/:skjemaId/:innsendingsKode",
          ]}
          component={withLayout(Svar)}
        />
        <SentryRoute component={withLayout(Default)} />
      </Switch>
      <PopinManager />
      <SlowAction />
    </>
  );
};

export default App;
